import { api } from '@/api'
import { PrecalcPaymentRequest, PrecalcPaymentResponse } from '@roolz/types/api/billing'
import { useResetState } from 'ahooks'
import { debounce } from 'lodash-es'
import { useCallback, useMemo, useRef } from 'react'

interface Params {
  debounceMS?: number
}

type State = {
  loading: boolean
  precalc: PrecalcPaymentResponse | null
}

type Actions = {
  precalcPayment: (calc: PrecalcPaymentRequest) => Promise<void | undefined>
  precalcPaymentDebounced: (calc: PrecalcPaymentRequest) => (Promise<void> | undefined)

  resetPrecalc: () => void
}

const defaultState = {
  loading: false,
  precalc: null,
}

const DEFAULT_DEBOUNCE_MS = 700

export const usePaymentPrecalc = (params?: Params): [State, Actions] => {
  const [state, setState, resetState] = useResetState<State>(defaultState)

  const lastReqId = useRef(0)

  const load = (params: PrecalcPaymentRequest, reqId: number) => api.billing.precalcPayment(params)
    .then(({ data }) => {
      if(reqId !== lastReqId.current) return

      setState({
        loading: false,
        precalc: data,
      })
    })
    .catch(e => {
      if(reqId !== lastReqId.current) return

      resetState()
      throw e
    })

  const loadDebounced = useMemo(
    () => debounce(load, params?.debounceMS || DEFAULT_DEBOUNCE_MS),
    [],
  )

  const precalcPayment = useCallback((params: PrecalcPaymentRequest) => {
    setState({
      loading: true,
      precalc: null,
    })

    return load(params, ++lastReqId.current)
  }, [])

  const precalcPaymentDebounced = useCallback((params: PrecalcPaymentRequest) => {
    setState({
      loading: true,
      precalc: null,
    })

    return loadDebounced(params, ++lastReqId.current)
  }, [loadDebounced])

  return [state, {
    precalcPayment,
    precalcPaymentDebounced,
    resetPrecalc: resetState,
  }]
}
