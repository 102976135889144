import { PaymentPeriods, TariffPlan } from '@roolz/types/api/billing'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { VatLabel } from '@roolz/sdk/components/pricing/ui/VatLabel/VatLabel'
import { useBreakpoint } from '@roolz/sdk/hooks/useBreakpoint'
import { sortPlansForMobile } from '@roolz/sdk/components/pricing/utils/plans'
import { usePricingPeriodState } from '@roolz/sdk/components/pricing/hooks/usePricingPeriodState'
import { AnnualPeriodChip } from '@roolz/sdk/components/pricing/ui/AnnualPeriodChip/AnnualPeriodChip'
import { Plan } from '@roolz/sdk/components/pricing/blocks/Plan/Plan'
import { SelectType } from '@roolz/sdk/components/ui/SelectType/SelectType'
import { useElementSize } from '@roolz/sdk/hooks/useElementSize'
import { useSdkTranslation } from '@roolz/sdk/SdkContext'
import styles from './Plans.module.scss'

export const Plans = observer(({
  hideRegister = false,

  defaultPaymentPeriod,

  currentPaymentPeriod,
  currentPlanType,
  minWidthForHorizontal,
  tariffs,

  itemsClassName,
  onConnect,
}: {
  hideRegister?: boolean

  defaultPaymentPeriod?: PaymentPeriods
  currentPaymentPeriod?: PaymentPeriods
  currentPlanType?: TariffPlan['type']
  minWidthForHorizontal: number
  tariffs: TariffPlan[]

  itemsClassName?: string
  onConnect?: (tariff: TariffPlan, period: PaymentPeriods) => Promise<void>
}) => {
  const { t } = useSdkTranslation('pricing')
  const isMobile = useBreakpoint({ maxWidth: 835 })

  const ref = useRef<HTMLDivElement | null>(null)
  const { width } = useElementSize(ref)

  const [paymentPeriod, setPaymentPeriod] = usePricingPeriodState(defaultPaymentPeriod)

  const types = [
    {
      value: PaymentPeriods.Monthly,
      title: t('plans.payment_periods.monthly'),
    },
    {
      value: PaymentPeriods.Annual,
      title: t('plans.payment_periods.annual'),
    },
  ]

  const processedTariffs = useMemo(() => (isMobile
    ? sortPlansForMobile(tariffs)
    : tariffs), [isMobile, tariffs])

  return (
    <div
      className={styles.root}
      ref={ref}

    >
      <div className={styles.type}>
        <SelectType
          className={styles.selectType}
          itemClassName={styles.selectType__item}
          value={paymentPeriod}
          onChange={setPaymentPeriod}

          items={types}
        />

        <AnnualPeriodChip/>
      </div>

      <div className={cn(styles.items, itemsClassName)}>
        {processedTariffs.map(tariff => (
          <Plan
            hideRegister={hideRegister}
            isCurrent={tariff.type === currentPlanType && currentPaymentPeriod === paymentPeriod}
            mode={
              tariff.type === 'enterprise' && width > minWidthForHorizontal
                ? 'horizontal'
                : 'vertical'
            }
            name={t(`plans.items.${tariff.type}.title`)}
            plan={tariff}
            paymentPeriod={paymentPeriod}
            onConnect={() => onConnect?.(tariff, paymentPeriod)}
            // currencySymbol={getCurrencySymbol(getTariffPriceInfo(tariff)?.currency)}
          />
        ))}
      </div>
    </div>
  )
})
