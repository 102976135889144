import InfoIcon from '@material-symbols/svg-400/rounded/info.svg'
import { MaterialSymbolIcon } from '@roolz/icons/MaterialSymbolIcon'
import * as React from 'react'
import cn from 'classnames'
import { useSdkTranslation } from '@roolz/sdk/SdkContext'
import styles from './VatLabel.module.scss'

interface Props {
  className?: string
}
export const VatLabel = ({
  className,
}: Props) => {
  const { t } = useSdkTranslation('pricing')

  return (
    <div className={cn(styles.root, className)}>
      <MaterialSymbolIcon
        icon={<InfoIcon/>}
        size={20}
        className={styles.icon}
      />

      <div className={styles.text}>
        {t('plans.vat_label')}
      </div>
    </div>
  )
}
