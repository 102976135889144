import { cancelAccountDeletionRequest, createInvitationRequest } from '@roolz/types/api/companies'
import {
  AdminProfile,
  changePasswordRequest,
  changePasswordResponse,
  checkIfEmailBusyRequest,
  checkIfEmailBusyResponse,
  DeleteAuthPhoneRequest,
  deleteOwnEmailRequest,
  deleteOwnEmailResponse,
  deleteOwnPasswordRequest,
  deleteOwnPasswordResponse,
  deleteOwnProfileRequest,
  Device,
  GetAllAdminProfilesRequest,
  GetAllAdminProfilesResponse,
  GetProfileByIdRequest,
  LoadProfilesByIdsRequest,
  PatchProfileRequest,
  Profile,
  TiedSocialNetworks,
  untieSocialNetworkRequest,
  updateOwnEmailRequest,
  updateOwnEmailResponse,
  updateOwnPhoneRequest,
  ValidateCurrentPasswordRequest,
} from '@roolz/types/api/profiles'
import { Country } from '@roolz/types/custom'
import { AxiosInstance } from 'axios'
import { ApiClients } from '@roolz/api/clients'

export type UpdateProfileRequest = Omit<Partial<AdminProfile>, 'country'> & {
  country_id?: Country['id']
}

export class ProfilesApi {
  identity: AxiosInstance
  admin: AxiosInstance

  constructor(clients: ApiClients) {
    this.identity = clients.identity
    this.admin = clients.admin
  }

  getById(id: Profile['id'], params?: GetProfileByIdRequest) {
    return this.identity.get<Profile>(`/v1/private/profile/${id}`, { params })
  }

  createInvitation(user_id: string, body: createInvitationRequest) {
    return this.identity.post(`/v1/profile/${user_id}/invitation/create`, body)
  }

  cancelDeletionByToken(body: cancelAccountDeletionRequest) {
    return this.identity.post('/v1/public/profile/cancel_deletion_by_token', body)
  }

  getByIds(params: LoadProfilesByIdsRequest) {
    // // @ts-ignore
    // params.profile_ids = params.profile_ids.join(',')

    return this.identity.get<Profile[]>('/v1/profile/list/by_ids', { params })
  }

  retrieveMe() {
    return this.identity.get<Profile>('/v1/private/me/profile')
  }

  retrieveMyDevice() {
    return this.identity.get<Device>('/v1/private/me/device')
  }

  getMySocialNetworks() {
    return this.identity.get<TiedSocialNetworks>('/v1/private/me/profile/socials')
  }

  untieSocialNetwork(body: untieSocialNetworkRequest) {
    return this.identity.post<TiedSocialNetworks>('/v1/private/me/profile/delete_social', body)
  }

  checkIfEmailBusy(params: checkIfEmailBusyRequest) {
    return this.identity.get<checkIfEmailBusyResponse>('/v1/public/profile/check_email', { params })
  }

  updateMyProfile(body: Partial<PatchProfileRequest>) {
    return this.identity.patch<Profile>('/v1/private/me/profile', body)
  }

  deleteOwnProfile(body: deleteOwnProfileRequest) {
    return this.identity.post<Profile>('/v1/private/me/profile/delete', body)
  }

  deleteOwnProfileDeletion() {
    return this.identity.post<Profile>('/v1/private/me/profile/cancel_deletion')
  }

  changePassword(body: changePasswordRequest) {
    return this.identity.post<changePasswordResponse>('/v1/private/me/profile/update_auth_password', body)
  }

  deleteOwnEmail(body?: deleteOwnEmailRequest) {
    return this.identity.post<deleteOwnEmailResponse>('/v1/private/me/profile/delete_auth_email', body)
  }

  validateCurrentPassword(body?: ValidateCurrentPasswordRequest) {
    return this.identity.post('/v1/private/me/profile/validate_password', body)
  }

  updateOwnEmail(body: updateOwnEmailRequest) {
    return this.identity.post<updateOwnEmailResponse>('/v1/private/me/profile/update_auth_email', body)
  }

  updateOwnPhone(body: updateOwnPhoneRequest) {
    return this.identity.post('/v1/private/me/profile/update_auth_phone', body)
  }

  deleteOwnPhone(body?: DeleteAuthPhoneRequest) {
    return this.identity.post('/v1/private/me/profile/delete_auth_phone', body)
  }

  deleteOwnPassword(body: deleteOwnPasswordRequest) {
    return this.identity.post<deleteOwnPasswordResponse>('/v1/private/me/profile/delete_auth_password', body)
  }

  /**
   * ADMIN ROUTES
   * */

  getAllProfiles({
    params,
    body,
  }: GetAllAdminProfilesRequest) {
    return this.admin.post<GetAllAdminProfilesResponse>('v1/profile/list', body, { params })
  }

  getProfileById(id: AdminProfile['id'], params?: GetProfileByIdRequest) {
    return this.admin.get<AdminProfile>(`/v1/profile/id/${id}`, { params })
  }

  updateProfileById(id: AdminProfile['id'], body: UpdateProfileRequest) {
    return this.admin.patch<AdminProfile>(`/v1/profile/id/${id}`, body)
  }

  banProfile(id: AdminProfile['id'], body: { duration: string }) {
    return this.admin.post(`/v1/profile/id/${id}/ban`, undefined, {
      params: {
        ban_duration: body.duration,
      },
    })
  }

  unbanProfile(id: AdminProfile['id']) {
    return this.admin.delete(`/v1/profile/id/${id}/ban`)
  }

  deleteProfile(id: AdminProfile['id'], body: { reason: string }) {
    return this.admin.delete(`/v1/profile/id/${id}`, {
      params: {
        deletion_reason: body.reason,
      },
    })
  }

  banDevice(device_id: AdminProfile['id']) {
    return this.admin.post<AdminProfile>(`/v1/device/${device_id}/ban`, undefined, {
      params: {
        // 200 years
        ban_duration: '1843200h',
      },
    })
  }

  unbanDevice(device_id: AdminProfile['id']) {
    return this.admin.delete<AdminProfile>(`/v1/device/${device_id}/ban`)
  }
}
