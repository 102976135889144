import { observable } from 'mobx'
import { ReactNode, useEffect } from 'react'

interface ConfirmParams {
  title: string | ReactNode
  content?: ReactNode
  actions: ReactNode | ((props: { loading: boolean }) => ReactNode)

  maxWidth?: number

  onConfirm?: () => any // Keyboard confirm
  onClose?: () => void
}

interface ConfirmationContext {
  open: boolean
  params: ConfirmParams | null

  loading: boolean
}

const getDefaultValue = (): ConfirmationContext => ({ open: false, params: null, loading: false })

export const confirmationContext: ConfirmationContext = observable(getDefaultValue())

export function useConfirmation(loading?: boolean) {
  const ctx = confirmationContext

  useEffect(() => {
    ctx.loading = !!loading
  }, [loading])

  function confirm(params: ConfirmParams) {
    ctx.open = true
    ctx.loading = false
    ctx.params = params
    // if(ctx.params?.onConfirm) {
    //   ctx.params?.onConfirm?.()
    // }
  }

  function close() {
    ctx.open = false
    if(ctx.params?.onClose) {
      ctx.params?.onClose?.()
    }

    // wait for animation end
    setTimeout(() => {
      if(!ctx.open) {
        ctx.params = null
      }
    }, 250)
  }

  return {
    confirm,
    close,
  }
}
