import { Company } from '@roolz/types/api/companies'
import { Offer } from '@roolz/types/api/exchange'
import { Profile } from '@roolz/types/api/profiles'
import { Country } from '@roolz/types/custom'

export interface CommonTariffPrice {
  price_monthly: string
  price_annual: string
  price_annual_to_month: string

  price_credit: string
  min_seats: number
}

export interface TariffPrice extends CommonTariffPrice {
  country_code: Country['alpha2']
  currency: string
}

export interface TariffScope {
  offers_public_publish: number
  is_verification: boolean
  offers_private_publish: number
  offer_private_crprice: string
  private_exch_groups: number
  offer_priority: number
  offers_views: number
}

export type BillingUsedLimits = Pick<TariffScope,
'offers_public_publish'
| 'offers_private_publish'
| 'private_exch_groups'
| 'offers_views'
>

export enum BillingExpireState {
  Gross = 'gross',
  Dying = 'dying',
  Dead = 'dead',
  Active = 'active',
}

export interface Billing {
  _id: string

  active_users: number
  user_id: Profile['id'] | ''
  company_id: Company['id']
  billing_next_state_time: number
  tariff_id: HistoricalTariffPlan['_id']
  available_credits: string
  is_active: boolean
  expiration_time: string
  next_payment_at?: string

  increase_active_users: number

  downgrade_period?: PaymentPeriods
  downgrade_to?: TariffPlanType

  limits: BillingUsedLimits
  tariff_plan: HistoricalTariffPlan
  billing_period: PaymentPeriods

  prev_billing_period: PaymentPeriods | null
  prev_tariff_type: HistoricalTariffPlan['type'] | null

  billing_expire_state?: BillingExpireState

  created_at: string
  updated_at: string
}

export type PersonalBilling = Pick<Billing,
'expiration_time' |
'limits' |
'user_id' |
'_id'
>

export interface TariffPlan {
  type: TariffPlanType
  version: number
  is_custom: boolean

  default_price: CommonTariffPrice
  pricing: TariffPrice[]
  scope: TariffScope
}

export interface HistoricalTariffPlan extends TariffPlan {
  _id: string
}

export enum TariffPlanType {
  Beginner = 'beginner',
  Free = 'free',
  Pro = 'pro',
  Expert = 'expert',
  Enterprise = 'enterprise',
}

export enum PaymentPeriods {
  Monthly = 'month',
  Annual = 'year',
}

export enum BillingPermissionName {
  PublishPublicOffer = 'offers_public_publish',
  PublishPrivateOffer = 'offers_private_publish',
  PrivateExchangeGroups = 'private_exch_groups',
  OfferView = 'offers_views',
}

export interface PaymentCard {
  title: string
  first6: string
  last4: string
  expiry_month: string
  expiry_year: string
  card_type: 'visa' | 'mastercard'
}

export type PaymentSystem = 'yookassa' | 'stripe' | 'bepaid'

// TODO implement when will exist on backend
export enum PaymentMethodErrorSlug {
  InsufficientFunds = 'insufficient_funds',
  PaymentMethodInvalid = 'payment_method_invalid',
  BankOrPaymentError = 'bank_or_payment_error',
}

export interface PaymentMethod {
  _id: string
  user_id: Profile['id']
  company_id: Company['id']
  payment_system: PaymentSystem
  is_default: boolean
  card: PaymentCard
  error?: PaymentMethodErrorSlug | null
  created_at: string
  updated_at: string
}

export interface Transaction {
  _id: string
  payment_id: string
  tariff_plan_id: string
  credits_count: number
  user_id: string
  company_id: string
  payment_system: PaymentSystem
  amount: string
  currency: string
  is_paid: boolean
  internal_status: 'processing' | 'success' | 'cancel'
  internal_canceletion_reason: 'insufficient_funds' | 'payment_method_invalid' | 'bank_or_payment_error'
  is_temporary: boolean
  created_at: string
  updated_at: string
}

export type GetActiveTariffsResponse = TariffPlan[]
export type GetTariffInfoResponse = HistoricalTariffPlan

export type CheckIfHasViewedOffersRequest = {
  active_space_company_id: Company['id'] | null
  offer_ids: Array<Offer['_id']>
}

export type CheckIfHasViewedOffersResponse = Record<Offer['_id'], boolean>

export type GetCompanyBillingInfoRequest = {
  active_space_company_id: Company['id'] | null
}
export type GetCompanyBillingInfoResponse = Billing | PersonalBilling

export type CheckBillingPermissionRequest = {
  active_space_company_id?: Company['id'] | null
  resource_name: BillingPermissionName
}
export type CheckBillingPermissionResponse = {
  // resource_name
}

export interface ChangeSubscriptionRequest {
  body: {
    with_new_payment_method?: boolean
    error_url: string
    success_url: string
    tariff_plan_period?: PaymentPeriods
    tariff_plan_type?: TariffPlanType

    up_active_users?: number
    credits_count?: number
  }
  params: {
    active_space_company_id: Company['id']
  }
}

export type PrecalcPaymentRequest = {
  body: Pick<ChangeSubscriptionRequest['body'],
  'tariff_plan_period' |
  'tariff_plan_type' |
  'up_active_users' |
  'credits_count'
  >,
  params: ChangeSubscriptionRequest['params']
}

export interface CancelSubscriptionRequest {
  active_space_company_id: Company['id']
}

export type GetPaymentMethodsRequest = {
  active_space_company_id: Company['id']
}
export type AddPaymentMethodRequest = {
  params: {
    active_space_company_id: Company['id']
  }
  body: {
    success_url: string
    error_url: string
  }
}
export type DeletePaymentMethodRequest = GetPaymentMethodsRequest
export type GetTransactionListRequest = {
  active_space_company_id: Company['id']
  transaction_type: 'payments',
  limit?: number
  offset?: number
}

export type GetTransactionListResponse = {
  total: number
  data: Transaction[]
}
export type GetPaymentMethodsResponse = PaymentMethod[]

export interface PrecalcPaymentResponse {
  payment_system: PaymentSystem
  amount: string
  currency: string

  active_users?: number
  credits_count?: number
  users_seats?: number
  item_amount?: string
  pre_paid_amount?: string
  days_to_be_paid?: number
}
