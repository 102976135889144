import { enterprisePlanModel } from '@roolz/sdk/components/pricing/utils/plans'
import {
  Billing,
  BillingPermissionName,
  PaymentPeriods,
  PersonalBilling,
  TariffPlan,
  TariffPlanType,
} from '@roolz/types/api/billing'
import { find } from 'lodash-es'
import { makeAutoObservable } from 'mobx'
import { profilesStore } from '@/store/profiles/profiles.store'

export class BillingStore {
  private _activePlans: TariffPlan[] = []
  billing: Billing
  personalBilling: PersonalBilling

  // currentBillingActionParams: ChangeSubscriptionRequest | null = null

  get paymentPeriodsAsceding() {
    return [
      PaymentPeriods.Monthly,
      PaymentPeriods.Annual,
    ]
  }

  get plansAsceding() {
    return this._activePlans
  }

  get currentSpaceTariffPlan(): TariffPlan {
    const activeSpace = profilesStore.activeCompanyId

    const freePlan = find(this.activePlans, { type: TariffPlanType.Free })

    if(activeSpace === null) {
      return freePlan as TariffPlan
    }

    return this.billing?.tariff_plan || freePlan
  }

  set activePlans(plans: TariffPlan[]) {
    this._activePlans = plans
  }

  get activePlans() {
    return [
      ...this._activePlans,
      enterprisePlanModel,
    ]
  }

  get canTopUpInAdvance() {
    const billing = this.billing

    return billing
      && (!!Number(billing?.available_credits)
      || billing.tariff_plan?.type !== TariffPlanType.Free
      || billing.limits.offers_private_publish > billing.tariff_plan?.scope?.offers_private_publish
      )
  }

  stripePaymentIntentKey: string | null = null
  stripePaymentSuccessUrl: string | null = null
  stripePaymentAmount: string | null = null
  stripePaymentCurrency: string | null = null

  stripeManualRedirectHandler: ((errorOrSuccessUrl: string) => void) | null = null
  onStripeFinish?: (() => void) | null = null

  resetStripe() {
    this.onStripeFinish?.()

    this.onStripeFinish = null
    this.stripePaymentIntentKey = null
    this.stripePaymentSuccessUrl = null
    this.stripeManualRedirectHandler = null
  }

  // unknown error may appear if some of backend endpoints return 402 permission name, that isn't supported on front side
  billingError: BillingPermissionName | 'unknown' | null
  // lackingSeatsCount: null | number

  findPlan(type: TariffPlanType) {
    return find(this.activePlans, { type })
  }

  get nextTariffPlan(): TariffPlan | undefined {
    const currentPlanType = this.currentSpaceTariffPlan?.type

    const i = this.activePlans.findIndex(plan => plan.type === currentPlanType)

    return this.activePlans.at(i + 1)
  }

  updateBilling(billing: Billing) {
    this.billing = billing
  }

  updatePersonalBilling(billing: PersonalBilling) {
    this.personalBilling = billing
  }

  constructor() {
    makeAutoObservable(this)
  }
}

export const billingStore = new BillingStore()
