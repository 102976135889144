import { useBillingPlanView } from '@roolz/sdk/components/pricing/hooks/useBillingPlanView'
import { concatCurrency } from '@roolz/sdk/components/pricing/utils/plans'
import { PaymentPeriods, TariffPlan } from '@roolz/types/api/billing'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import styles from './NewTariffPlan.module.scss'

interface Props {
  plan: TariffPlan
  period: PaymentPeriods

  companyMembersCount: number

  className?: string
}

export const NewTariffPlan = observer(({
  plan,
  period,

  companyMembersCount,

  className,
}: Props) => {
  const { t } = useTranslation('pricing')
  const { t: companyPricingT } = useTranslation('company/pricing')

  const {
    currencySymbol,
    price,
    priceType,

    Icon,
  } = useBillingPlanView(plan, {
    paymentPeriod: period,
  })

  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.header}>
        <div className={styles.icon}>
          {Icon}
        </div>
        <div className={styles.header__content}>
          <div className={styles.name}>
            {companyPricingT(`plans.${plan.type}.name`)}
          </div>
          <div className={styles.price}>
            <span className={styles.price__amount}>
              {concatCurrency(currencySymbol, price)}
            </span>
            <span className={styles.price__type}>
              /
              {period === 'year' && (
                <>
                  {t('plans.price_types.per_month')}
                  <br/>
                </>
              )}
              {t(`plans.price_types.${priceType}`)}
            </span>
          </div>
        </div>
      </div>

      <div className={styles.members}>
        <span>
          {t('modals:new_subscription_calc.company_members_count')}
        </span>
        <span className={styles.members__count}>
          {companyMembersCount}
        </span>
      </div>
    </div>
  )
})
