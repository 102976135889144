import { toastWarning } from '@roolz/sdk/components/snackbars'
import Button from '@roolz/sdk/components/ui/buttons/Button/Button'
import { useLayoutStateRef } from '@roolz/sdk/hooks/helpers/useStateRef'
import { useModalControl } from '@roolz/sdk/hooks/useModalControl'
import { PaymentPeriods, TariffPlan, TariffPlanType } from '@roolz/types/api/billing'
import { Company } from '@roolz/types/api/companies'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { createContext, ReactNode, useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  NewSubscriptionConfirmation, NewSubscriptionConfirmationProps,
} from '@/components/modals/billing/NewSubscriptionConfirmation/NewSubscriptionConfirmation'
import { NoPermissionError } from '@/components/modals/billing/NoPermissionError/NoPermissionError'
import { SelectPremiumPlan } from '@/components/modals/billing/SelectPremiumPlan/SelectPremiumPlan'
import { CreateCompanyShort } from '@/components/modals/companies/CreateCompanyShort/CreateCompanyShort'
import { LoadingOverlay } from '@/components/ui/LoadingOverlay/LoadingOverlay'
import { MAX_COMPANIES_COUNT } from '@/config/const'
import { useConfirmation } from '@/confirmation/ConfirmationContext'
import { billingService } from '@/store/billing/billing.service'
import { billingStore } from '@/store/billing/billing.store'
import { companiesService } from '@/store/companies/companies.service'
import { companiesStore } from '@/store/companies/companies.store'
import { myCompaniesStore } from '@/store/companies/my_companies.store'
import { profilesStore } from '@/store/profiles/profiles.store'

// Probably context is not the best choice. Rewrite later
export type ContextType = {
  changePlan: (period?: PaymentPeriods) => void
}
export const ChangeTariffProcessContext = createContext<ContextType>({
  changePlan: () => {
  },
})

// type Children = (props: {
//   changePlan: (period?: PaymentPeriods) => void
// }) => ReactNode

type Props = {
  open?: boolean
  children?: ReactNode
}

export const ChangeTariffProcess = observer(({
  open,
  children,
}: Props) => {
  const { t } = useTranslation('company/pricing')

  const {
    t: modalsT,
    i18n: { language },
  } = useTranslation('modals')

  const confirmation = useConfirmation()

  // const calculationsModal = useModalControl(false)
  const selectPlan = useModalControl(open)

  const [paymentInNewTab, setPaymentInNewTab] = useState(false)

  const createCompany = useModalControl()

  const [loading, setLoading] = useState(false)
  const loadingRef = useLayoutStateRef(loading)

  const [defaultPeriod, setDefaultPeriod] = useState<PaymentPeriods | undefined>()

  const confirmPlanChange = (type: TariffPlanType, period: PaymentPeriods) => new Promise(resolve => {
    const onConfirm = () => {
      confirmation.close()

      return resolve(true)
    }

    const onReject = () => {
      confirmation.close()

      return resolve(false)
    }

    confirmation.confirm({
      title: t('change_plan_modal.title'),
      content: (
        <Trans
          i18nKey='company/pricing:change_plan_modal.description'
          values={{
            planName: t(`plans.${type}.name`),
            planPeriod: t(`payment.payment_period.${period}`),
          }}
        />
      ),
      actions: (
        <>
          <Button variant='text' onClick={onReject}>
            {t('change_plan_modal.cancel')}
          </Button>
          <Button
            variant='text'
            onClick={onConfirm}
          >
            {t('change_plan_modal.confirm')}
          </Button>
        </>
      ),
      onConfirm,
    })
  })

  const [onCompanyCreated, setOnCompanyCreated] = useState<((company: Company) => void) | undefined>()

  const [precalcConfirmProps, setPrecalcConfirmProps] = useState<NewSubscriptionConfirmationProps | null>(null)

  const noPermissionModal = useModalControl()

  const handlePlanSelected = async (plan: TariffPlan, period: PaymentPeriods) => {
    if(!companiesStore.canManageActiveCompany) {
      return noPermissionModal.show()
    }
    const currentPlan = billingStore.currentSpaceTariffPlan
    if(currentPlan.is_custom === true) {
      return showCustomPlanChangeWarning()
    }

    if(plan.type === TariffPlanType.Enterprise) {
      window.open('mailto:hi@roolz.net', '_blank')

      return
    }

    const companyId = profilesStore.activeCompanyId
    const myCompanies = myCompaniesStore.companies

    if(!companyId) {
      // TODO think what to do if I'm not an admin of company
      if(myCompanies.length < MAX_COMPANIES_COUNT) {
        setOnCompanyCreated(() => (company: Company) => {
          showCalculations(company.id, plan, period)
          companiesService.loadMyCompanies()
        })
        selectPlan.close()
        createCompany.show()
      }

      return
    }

    return showCalculations(companyId, plan, period)
  }

  const showCustomPlanChangeWarning = useCallback(() => {
    confirmation.confirm({
      title: modalsT('custom_plan_change.title'),
      content: (
        <Trans
          i18nKey='modals:custom_plan_change.description'
          components={{
            email: <a href='mailto:hi@roolz.net' target='_blank' rel='noreferrer'/>,
          }}
          values={{
            email: 'hi@roolz.net',
          }}
        />
      ),
      actions: (
        <Button fullWidth onClick={confirmation.close}>{modalsT('custom_plan_change.close')}</Button>
      ),
    })
  }, [confirmation, language])

  const showCalculations = useCallback(async (companyId: Company['id'], plan: TariffPlan, period: PaymentPeriods) => {
    const onConfirm = () => finishPlanChange(companyId, plan.type, period)

    const onDowngrade = async () => {
      if(await confirmPlanChange(plan.type, period)) {
        onConfirm()
      }
    }

    setPrecalcConfirmProps({
      companyId,
      plan,
      period,
      onConfirm,
      onDowngrade,
    })
  }, [])

  const finishPlanChange = useCallback(async (companyId: Company['id'], type: TariffPlanType, period: PaymentPeriods) => {
    if(loadingRef.current) return

    setLoading(true)

    try {
      await billingService.changePlan(companyId, type, period, paymentInNewTab)

      if(paymentInNewTab) {
        selectPlan.close()
      }
    } catch(e) {
      toastWarning(e?.response?.data?.error_msg ?? t('errors:insufficient_request'))
    } finally {
      setLoading(false)
    }
  }, [paymentInNewTab])

  const changePlan = useCallback((period?: PaymentPeriods, newTab = false) => {
    setDefaultPeriod(period)

    setPaymentInNewTab(newTab)
    selectPlan.show()
  }, [])

  useEffect(() => {
    if(!selectPlan.state.open) {
      setDefaultPeriod(undefined)
    }
  }, [selectPlan.state.open])

  return (
    <ChangeTariffProcessContext.Provider value={{ changePlan }}>
      {children}

      <SelectPremiumPlan
        {...selectPlan.state}
        defaultPeriod={defaultPeriod}
        onConnect={handlePlanSelected}
      />

      <CreateCompanyShort
        {...createCompany.state}
        onSuccess={onCompanyCreated}
      />

      {precalcConfirmProps && (
        <NewSubscriptionConfirmation
          open={!!precalcConfirmProps}
          setOpen={() => setPrecalcConfirmProps(null)}
          {...precalcConfirmProps}
        />
      )}

      <LoadingOverlay
        open={loading}
        fixed
      />

      <NoPermissionError {...noPermissionModal.state}/>
    </ChangeTariffProcessContext.Provider>
  )
})
