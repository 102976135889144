import { concatCurrency } from '@roolz/sdk/components/pricing/utils/plans'
import { PrecalcPaymentResponse } from '@roolz/types/api/billing'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { exchangeKnowledgeStore } from '@/store/knowledge/stores/exchange-knowledge.store'
import { billingStore } from '@/store/billing/billing.store'
import styles from '@/components/company-admin/pricing/ui/PaymentCalculations/PaymentCalculations.module.scss'

interface Props {
  type: 'extraSeats' | 'subscription' | 'credits'
  precalc: PrecalcPaymentResponse
}

export const PaymentCalculations = observer(({
  type,
  precalc,
}: Props) => {
  const { t, i18n: { language } } = useTranslation('pricing')

  const vatPercent = 20

  const currency = exchangeKnowledgeStore.findSupportedCurrencyBySlug(precalc.currency)

  const itemAmount = Number(precalc.item_amount).toFixed(2)
  const totalAmount = Number(precalc.amount).toFixed(2)
  const activeUsers = Number(precalc.active_users)

  const calcText = useMemo(() => {
    if(type === 'extraSeats') {
      return t('payment_calc.extraSeats', {
        seatPrice: concatCurrency(currency?.symbol || precalc.currency, itemAmount),
        extraSeatsCount: precalc.users_seats,
        vat: vatPercent,
        daysCount: precalc.days_to_be_paid,
      })
    }

    if(type === 'subscription') {
      return t('payment_calc.subscription', {
        seatPrice: concatCurrency(currency?.symbol || precalc.currency, itemAmount),
        membersCount: activeUsers,
        vat: vatPercent,
      })
    }

    if(type === 'credits') {
      return t('payment_calc.credits', {
        vat: vatPercent,
        crPrice: concatCurrency(currency?.symbol || precalc.currency, itemAmount),
        extraCrCount: precalc.credits_count,
      })
    }

    return null
  }, [type, precalc, currency, vatPercent, language, itemAmount, activeUsers])

  return (
    <>
      {calcText && (
        <div className={styles.calc}>
          {calcText}
        </div>
      )}

      {precalc.pre_paid_amount && (
        <div className={styles.calc}>
          <Trans
            i18nKey='pricing:payment_calc.prepaid_amount'
            values={{
              amount: concatCurrency(currency?.symbol || precalc.currency, Number(precalc.pre_paid_amount)),
            }}
          />
        </div>
      )}

      <div className={styles.amountDescription}>
        <Trans
          i18nKey='pricing:payment_calc.total_amount'
          values={{
            amount: concatCurrency(currency?.symbol || precalc.currency, totalAmount),
          }}
          components={{
            amount: <span className={styles.amountDescription__amount}/>,
          }}
        />
      </div>
    </>
  )
})
