import { Intro } from '@roolz/sdk/components/pricing/blocks/Intro/Intro'
import { Plans } from '@roolz/sdk/components/pricing/blocks/Plans/Plans';
import { Scope } from '@roolz/sdk/components/pricing/blocks/Scope/Scope'
import { usePricingPeriodState } from '@roolz/sdk/components/pricing/hooks/usePricingPeriodState'
import { VatLabel } from '@roolz/sdk/components/pricing/ui/VatLabel/VatLabel'
import { CloseButton } from '@roolz/sdk/components/ui/buttons/CloseButton/CloseButton'
import FAQ from '@roolz/sdk/components/ui/FAQ/FAQ'
import { universalRoolzStaticUrl } from '@roolz/sdk/utils/urls'
import { PaymentPeriods, TariffPlan, TariffPlanType } from '@roolz/types/api/billing';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import { billingStore } from '@/store/billing/billing.store';
import { Modal, ModalProps } from '@/components/hoc/Modal';
import { systemStore } from '@/store/system/system.store'
import styles from './SelectPremiumPlan.module.scss';

type Props = {
  defaultPeriod?: PaymentPeriods
  onConnect?: (tariff: TariffPlan, period: PaymentPeriods) => Promise<void>
} & ModalProps

export const SelectPremiumPlan = Modal<Props>(observer(({
  defaultPeriod,
  onConnect,
  setOpen,
}: Props) => {
  const { t: faqT } = useTranslation('faq')

  const [paymentPeriod] = usePricingPeriodState()

  const tariffs = billingStore.activePlans
  const currentPlanType = billingStore.currentSpaceTariffPlan?.type ?? TariffPlanType.Free
  const currentPaymentPeriod = billingStore.billing?.billing_period

  useTranslation('pricing')

  const initialPeriod = useMemo(() => {
    if(defaultPeriod && Object.values(PaymentPeriods).includes(defaultPeriod)) {
      return defaultPeriod
    }

    return currentPlanType !== TariffPlanType.Free
      ? currentPaymentPeriod
      : PaymentPeriods.Annual
  }, [defaultPeriod, currentPlanType, currentPaymentPeriod])

  const publicFaqQuestions = faqT('public_exchange', { returnObjects: true })
  const privateFaqQuestions = faqT('private_exchange', { returnObjects: true })

  const faqQuestions = [
    publicFaqQuestions.at(0),
    privateFaqQuestions.at(0),
    privateFaqQuestions.at(1),
    publicFaqQuestions.at(1),
    publicFaqQuestions.at(2),
    publicFaqQuestions.at(3),
    privateFaqQuestions.at(4),
  ]

  return (
    <div className={styles.root}>
      <CloseButton
        className={styles.close}
        onClick={() => setOpen(false)}
      />

      <div className={styles.container}>
        <Intro/>

        <Plans
          hideRegister
          defaultPaymentPeriod={initialPeriod}
          currentPaymentPeriod={currentPlanType !== TariffPlanType.Free
            ? currentPaymentPeriod
            : paymentPeriod}
          currentPlanType={currentPlanType}
          minWidthForHorizontal={940}
          itemsClassName={cn(styles.itemsContainer, styles.items)}
          tariffs={tariffs}
          onConnect={onConnect}
        />

        <VatLabel className={cn(styles.itemsContainer, styles.vatLabel)}/>
      </div>

      <Scope
        tariffs={tariffs}
        onConnect={onConnect}
        currentPlanType={currentPlanType}
        currentPlanPeriod={currentPlanType !== TariffPlanType.Free
          ? currentPaymentPeriod
          : paymentPeriod}
        initialPaymentPeriod={initialPeriod}
        hideRegister
      />

      <FAQ
        customQuestions={faqQuestions as any[]}

        theme='light'
        animate={false}
        i18n_namespace='pricing'
        i18n_prefix='faq'
      />

      <PaymentSystems/>
    </div>
  )
}), {
  fullWidth: true,
  maxWidth: false,
  classes: {
    root: styles.dialog,
    paper: styles.paper,
  },
  // className: styles.root
})

const PaymentSystems = observer(() => {
  const country = systemStore.userCountry
  const countryAlpha2 = country?.alpha2 ?? ''

  return (
    <div className={styles.paymentSystems}>
      <img
        src={`${universalRoolzStaticUrl}/images/pricing/payment_systems/world.svg`}
        alt=''
      />

      {countryAlpha2?.toLowerCase?.() === 'by' && (
        <img
          src={`${universalRoolzStaticUrl}/images/pricing/payment_systems/by.svg`}
          alt=''
        />
      )}

      {countryAlpha2?.toLowerCase?.() === 'ru' && (
        <img
          src={`${universalRoolzStaticUrl}/images/pricing/payment_systems/ru.svg`}
          alt=''
        />
      )}
    </div>
  )
})
