import Button from '@roolz/sdk/components/ui/buttons/Button/Button'
import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useConfirmation } from '@/confirmation/ConfirmationContext'

export const useCustomPlanInvitationWarning = () => {
  const { t } = useTranslation('modals', {
    keyPrefix: 'custom_plan_extra_invitations_warning',
  })

  const confirmation = useConfirmation()

  const show = () => {
    confirmation.confirm({
      title: t('title'),
      content: (
        <Trans
          ns='modals'
          i18nKey='custom_plan_extra_invitations_warning.description'
          components={{
            email: <a href='mailto:hi@roolz.net' target='_blank' rel='noreferrer'/>,
          }}
          values={{
            email: 'hi@roolz.net',
          }}
        />
      ),
      actions: (
        <Button fullWidth onClick={confirmation.close}>{t('close')}</Button>
      ),
    })
  }

  return show
}
