import cn from 'classnames'
import { forwardRef, ReactElement, ReactNode, useMemo, useRef } from 'react'
import { UCFirst } from '@roolz/sdk/utils/formatting'
import styles from './Button.module.scss'

export enum Color {
  primary = 'primary',
  danger = 'danger',
  muted = 'muted',
  green = 'green',
  green2 = 'green2',
  white = 'white',

}

export enum Size {
  medium = 'medium',
  small = 'small',
}

export enum Variant {
  contained = 'contained',
  secondary = 'secondary',
  text = 'text',
  outlined = 'outlined',
}

export interface Props {
  children?: ReactNode | string,

  color?: `${Color}`
  size?: `${Size}`
  variant?: `${Variant}`
  type?: Pick<HTMLButtonElement, 'type'>

  onClick?: (...params: any) => void

  isIcon?: boolean

  fullWidth?: boolean

  Component?: string | ReactElement

  [key: string]: any
}

// add native button props
// type Props =
//   SubtructInterface<HTMLAttributes<HTMLButtonElement>, InternalProps>
//   & InternalProps

const Button = forwardRef(({
  children,
  color = Color.primary,
  size = Size.medium,
  variant = Variant.contained,
  isIcon = false,
  Component = 'button',
  fullWidth,
  onClick,
  ...rest
}: Props, ref: any) => {
  const localRef = useRef<any>()

  const colorClasses = useMemo(() => styles[`color${UCFirst(color)}`], [color])

  const sizeClasses = useMemo(() => styles[`size${UCFirst(size)}`], [size])

  const variantClasses = useMemo(() => styles[`variant${UCFirst(variant)}`], [variant])

  function handleClick(e: any) {
    if(!rest?.type) {
      // e.stopPropagation()
      // e.preventDefault()
    }

    onClick?.(e)
  }

  return (
    // @ts-ignore
    <Component
      {...rest}
      ref={ref ?? localRef}
      onClick={handleClick}
      className={cn(
        styles.button,
        colorClasses,
        sizeClasses,
        variantClasses,
        {
          [styles.buttonIcon]: isIcon,
          [styles.fullWidth]: fullWidth,
        },
        rest.className,
      )}
    >
      {children}
    </Component>
  )

  // return (
  //   <MuiButton
  //     variant='contained'
  //     ref={forwardRef ?? localRef}
  //     {...rest}
  //   >
  //     {children}
  //   </MuiButton>
  // )
})

export default Button
