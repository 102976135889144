import { api } from '@/api'
import { resolvePathByName, ROUTE_NAMES } from '@/config/routes'
import { Button } from '@mui/material'
import { toastError } from '@roolz/sdk/components/snackbars'
import { Checkbox } from '@roolz/sdk/components/ui/fields/Checkbox/Checkbox'
import { Select } from '@roolz/sdk/components/ui/fields/Select/Select'
import { usePrevious } from '@roolz/sdk/hooks/helpers/usePrevious'
import { VALIDATION_STATUS_CODES } from '@roolz/types/custom'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useCustomPlanInvitationWarning } from '@/components/company-admin/hooks/useCustomPlanInvitationWarning'
import { billingStore } from '@/store/billing/billing.store'
import { billingService } from '@/store/billing/billing.service'
import { useChangeTariff } from '@/components/company-admin/pricing/hooks/useChangeTariff'
import { ChangeTariffProcess } from '@/components/company-admin/pricing/processes/ChangeTariffProcess'
import { AddUsersLimitReached } from '@/components/modals/billing/AddUsersLimitReached/AddUsersLimitReached'
import { bus } from '@/events'
import { companiesService } from '@/store/companies/companies.service'
import { Label } from '@/components/ui/typography/Label/Label'
import { Loadable } from '@/components/ui/Loadable/Loadable'
import { EmailItem, EmailMultiSelect } from '@/components/ui/fields/EmailMultiSelect/EmailMultiSelect'
import styles from './InviteMembersForm.module.scss'

export const STATUSES = {
  LIMIT: 'limit',
  INCORRECT: 'incorrect',
  DUPLICATE: 'duplicate',
  ACCEPT: 'accept',
  DELETE: 'delete',
}

export interface Form {
  role: string
  emails: string[]
  profile_ids: string[]
  representative: boolean
}

const defaultValues: Form = {
  role: 'member',
  emails: [],
  profile_ids: [],
  representative: true,
}

type InviteMembersFormProps = {
  cancelText?: string
  acceptText?: string
  companyId: string

  onClose?: () => void
}

const getEmailItem = (email: string, status: string): EmailItem => {
  const item: EmailItem = {
    value: email,
    error: null,
    variant: 'error',
  }

  if([STATUSES.DELETE, STATUSES.LIMIT, STATUSES.INCORRECT].includes(status)) {
    return {
      ...item,
      error: status,
    }
  }

  if(status === STATUSES.DUPLICATE) {
    return {
      value: email,
      error: status,
      variant: 'warning',
    }
  }

  return {
    value: email,
    error: null,
  }
}

export const InviteMembersForm = ({
  cancelText,
  acceptText,
  companyId,

  onClose,
}: InviteMembersFormProps) => {
  const { t } = useTranslation('company/users')
  const { t: errorsT } = useTranslation('errors')
  const navigate = useNavigate()
  const ref = useRef<{ parseAndAddEmails:() => Promise<boolean> }>(null)
  const { changePlan } = useChangeTariff()

  const accept = acceptText || t('invite')
  const cancel = cancelText || t('cancel')

  const showCustomPlanWarning = useCustomPlanInvitationWarning()

  const [requiredSeats, setRequiredSeats] = useState<number | null>(null)

  const [loading, setLoading] = useState<boolean>(false)

  const validate = async (email: string) => {
    // return getEmailItem(email, STATUSES.ACCEPT)
    try {
      await api.companies.checkEmailInCompany(companyId, { email })

      return getEmailItem(email, STATUSES.ACCEPT)
    } catch(e: any) {
      const code = e?.response?.data?.error_code

      switch(code) {
        case VALIDATION_STATUS_CODES.INVALID_EMAIL:
          return getEmailItem(email, STATUSES.INCORRECT)
        case VALIDATION_STATUS_CODES.SPACE_LIMIT_EXCEEDED:
          return getEmailItem(email, STATUSES.LIMIT)
        case VALIDATION_STATUS_CODES.ENTITY_ALREADY_EXISTS:
          return getEmailItem(email, STATUSES.DUPLICATE)
        default:
          navigate(resolvePathByName(ROUTE_NAMES.PUBLIC_EXCHANGE))
      }

      return null
    }
  }

  const form = useForm<Form>({
    mode: 'onChange',
    defaultValues,
  })

  const onEmailChange = useCallback((value: string[]) => {
    form.setValue('emails', value)
  }, [])

  const roles = useMemo(() => [{
    value: 'member',
    label: t('roles.member'),
  }, {
    value: 'admin',
    label: t('roles.admin'),
  }], [])

  const onSubmit = async () => {
    if(!companyId || loading) return

    setLoading(true)

    const isValid = await ref.current?.parseAndAddEmails()
    if(!isValid) {
      return setLoading(false)
    }

    try {
      const data = form.getValues()

      if(!data.emails.length) {
        return onClose?.()
      }

      await companiesService.sendCompanyInvitation(companyId, data)

      bus.emit('companyAdmin/usersInvited', { companyId })
      onClose?.()
    } catch(e: any) {
      if(e?.response?.status === 402) {
        const seats = e?.response?.data?.required_seats

        const isCustomPlan = billingStore.currentSpaceTariffPlan?.is_custom

        if(isCustomPlan) {
          return showCustomPlanWarning()
        }

        if(seats) {
          return setRequiredSeats(seats)
        }
        if(seats === 0) {
          billingService.reloadCurrentBilling()

          onClose?.()

          return changePlan()
        }
      }

      console.error(e)
      toastError(e?.response?.data?.error_msg || errorsT('insufficient_request'))
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className={styles.formBlock}>
        <div className={styles.formGroup}>
          <Label
            htmlFor='role'
            required
          >
            {t('role')}
          </Label>
          <Controller
            control={form.control}
            name='role'
            render={({ field }) => (
              <Select
                id='role'
                fullWidth
                items={roles}
                allowEmpty={false}
                placeholder={t('member')}
                {...field}
              />
            )}
          />
        </div>
      </div>

      <Controller
        control={form.control}
        name='representative'
        render={({ field }) => (
          <div
            className={styles.checkbox}
            onClick={() => field.onChange(!field.value)}
          >
            <Checkbox
              id='representative'
              checked={field.value}
            />
            <span
              className={styles.checkbox__label}
            >
              {t('show_in_company_contacts')}
            </span>
          </div>
        )}
      />

      <EmailMultiSelect
        onChange={onEmailChange}
        validate={validate}
        ref={ref}
      />

      <div className={styles.actions}>
        <Button
          tabIndex={1}
          onClick={onClose}
        >
          {cancel}
        </Button>
        <Loadable
          loading={loading}
          delay={500}
        >
          <Button
            tabIndex={2}
            onClick={onSubmit}
            disabled={loading}
          >
            {accept}
          </Button>
        </Loadable>
      </div>

      <AddUsersLimitReached
        open={!!requiredSeats}
        setOpen={() => setRequiredSeats(null)}
        onSuccess={onSubmit}

        seatsCount={requiredSeats as number}
      />
    </>
  )
}
