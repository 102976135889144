import { DialogTitle } from '@mui/material'
import { Loadable } from '@roolz/sdk/components'
import { toastWarning } from '@roolz/sdk/components/snackbars'
import Button from '@roolz/sdk/components/ui/buttons/Button/Button'
import { CloseButton } from '@roolz/sdk/components/ui/buttons/CloseButton/CloseButton'
import { PaymentPeriods, TariffPlan } from '@roolz/types/api/billing'
import { useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { profilesStore } from '@/store/profiles/profiles.store'
import { Modal, ModalProps } from '@/components/hoc/Modal'
import { PaymentCalculations } from '@/components/company-admin/pricing/ui/PaymentCalculations/PaymentCalculations'
import { NewTariffPlan } from '@/components/company-admin/pricing/ui/NewTariffPlan/NewTariffPlan'
import { usePaymentPrecalc } from '@/components/company-admin/pricing/hooks/usePaymentPrecalc'
import styles from './NewSubscriptionConfirmation.module.scss'

export interface NewSubscriptionConfirmationProps {
  companyId?: string,

  plan: TariffPlan
  period: PaymentPeriods

  onConfirm?: () => void
  onDowngrade?: () => void
}

const MAX_TIME_FOR_INVISIBILITY = 500

export const NewSubscriptionConfirmation = Modal(({
  companyId,

  plan,
  period,

  onConfirm,
  onDowngrade,

  setOpen,
  // ...rest
}: NewSubscriptionConfirmationProps & ModalProps) => {
  const { t } = useTranslation('modals')

  const [{ precalc }, { precalcPayment }] = usePaymentPrecalc()

  const [mount, setMount] = useState(false)

  useEffect(() => {
    setTimeout(() => setMount(true), MAX_TIME_FOR_INVISIBILITY)
  }, [])
  useLayoutEffect(() => {
    if(precalc) setMount(true)
  }, [precalc])

  useLayoutEffect(() => {
    const companyIdForCheck = companyId || profilesStore.activeCompanyId

    if(!companyIdForCheck) return

    precalcPayment({
      body: {
        tariff_plan_type: plan.type,
        tariff_plan_period: period,
      },
      params: {
        active_space_company_id: companyIdForCheck,
      },
    })
      .catch((e: any) => {
        toastWarning(e?.response?.data?.error_msg ?? t('errors:insufficient_request'))
        setOpen(false)
      })
  }, [plan, period])

  if(!!precalc && (!precalc?.amount || Number(precalc?.amount) < 0)) {
    onDowngrade?.()
    setOpen(false)

    return null
  }

  if(!mount) return null

  return (
    <Loadable loading={!precalc}>
      <div className={styles.header}>
        <DialogTitle>
          {t('new_subscription_calc.title')}

          <CloseButton
            className={styles.close}
            onClick={() => setOpen(false)}
          />
        </DialogTitle>

      </div>
      <div className={styles.content}>

        <NewTariffPlan
          plan={plan}
          period={period}

          companyMembersCount={precalc?.active_users || 0}

          className={styles.newPlan}
        />
        {precalc && (
          <PaymentCalculations
            type='subscription'
            precalc={precalc}
          />
        )}

        <Button
          className={styles.confirm}
          onClick={onConfirm}
        >
          {t('new_subscription_calc.confirm')}
        </Button>
      </div>
    </Loadable>
  )
}, {
  classes: {
    root: styles.root,
    paper: styles.paper,
  },
})
